











import Vue from 'vue';
import Component from 'vue-class-component';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { Stream } from '@/models/data/models';

@Component({
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      Stream,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class StreamForm extends Vue {
  beforeSaveHook(stream: Stream) {
    if (this.$props.id === '0') {
      stream.application = this.$store.getters['global/application'].id;
    }
  }
}
